


























































import Vue from 'vue'

import Section from '@/components/layouts/main/Section.vue'
import BaseButton from '@/components/BaseButton.vue'
import IconUnLock from '@/components/icons/IconUnLock.vue'
import IconLogo from '@/components/icons/IconLogo.vue'
import { filterToken } from '@/utils/utils'

export default Vue.extend({
  components: {
    Section,
    BaseButton,
    IconUnLock,
    IconLogo,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      token: '',
      maxChar: 6,
    }
  },

  computed: {},

  watch: {
    token: function (newToken: string, oldToken: string) {
      this.token = filterToken(oldToken, newToken)
    },
  },
  methods: {},

  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      },
    },
  },
})
