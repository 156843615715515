import { AuthActionNames, SignInAction } from 'Auth/__types__/actions'
import { AuthMutationNames } from 'Auth/__types__/mutations'
import { NotifCenterActionNames } from 'NotifCenter/__types__/actions'
import { SignIn } from 'Services/__types__/authentication'

export default function buildAction(signInFunc: SignIn): SignInAction {
  return {
    async [AuthActionNames.SIGN_IN]({ commit, dispatch }, token) {
      commit(AuthMutationNames.SET_AUTH_LOADING, true)

      const status = await signInFunc(token)

      if (status.authenticated && status.apiToken) {
        commit(AuthMutationNames.SIGN_IN, status.apiToken)
      }

      // Display a notification related to status' props
      dispatch(
        ('notifCenter/' +
          NotifCenterActionNames.SET_NOTIF) as NotifCenterActionNames.SET_NOTIF,
        status,
        { root: true },
      )

      commit(AuthMutationNames.SET_AUTH_LOADING, false)
    },
  }
}
