import { UIActionNames, ToggleRightPanelAction } from 'UI/__types__/actions'
import { UIMutationNames } from 'UI/__types__/mutations'

export default function buildAction(): ToggleRightPanelAction {
  return {
    [UIActionNames.TOGGLE_RIGHT_PANEL]({ state, commit }) {
      commit(UIMutationNames.SET_RIGHT_PANEL, !state.isRightPanel)
    },
  }
}
