import { AuthActionNames, SignOutAction } from 'Auth/__types__/actions'
import { AuthMutationNames } from 'Auth/__types__/mutations'

export default function buildAction(): SignOutAction {
  return {
    [AuthActionNames.SIGN_OUT]({ commit }) {
      commit(AuthMutationNames.SIGN_OUT, undefined)
    },
  }
}
