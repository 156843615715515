














































import Vue from 'vue'

export default Vue.extend({
  props: {
    animate: {
      type: Boolean,
      default: true,
    },
  },
})
