/*
 * Module: UI
 */

import { UIState } from 'UI/__types__/state'

// Create initial state
export const buildUIState = function (): UIState {
  return {
    isLoading: false,
    isLeftPanel: false,
    isRightPanel: false,
    modal: {
      isVisible: false,
    },
  }
}
