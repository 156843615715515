/*
 * Module: UI
 */

import { ModalContent, UIState } from './state'
import { MutationTree } from 'vuex'

// Mutations enums
export enum UIMutationNames {
  SET_LOADING = 'SET_LOADING',
  SET_LEFT_PANEL = 'SET_LEFT_PANEL',
  SET_RIGHT_PANEL = 'SET_RIGHT_PANEL',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  SET_MODAL_CONTENT = 'SET_MODAL_CONTENT',
}

export interface UIMutationTree extends MutationTree<UIState>, UIMutations {}

// Mutation contracts
export type UIMutations<S = UIState> = {
  [UIMutationNames.SET_LOADING](state: S, set: boolean): void
  [UIMutationNames.SET_LEFT_PANEL](state: S, set: boolean): void
  [UIMutationNames.SET_RIGHT_PANEL](state: S, set: boolean): void
  [UIMutationNames.TOGGLE_MODAL](state: S, set: boolean): void
  [UIMutationNames.SET_MODAL_CONTENT](state: S, set: ModalContent): void
}
