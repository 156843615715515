import { UIActionNames, ToggleLeftPanelAction } from 'UI/__types__/actions'
import { UIMutationNames } from 'UI/__types__/mutations'

export default function buildAction(): ToggleLeftPanelAction {
  return {
    [UIActionNames.TOGGLE_LEFT_PANEL]({ state, commit }) {
      commit(UIMutationNames.SET_LEFT_PANEL, !state.isLeftPanel)
    },
  }
}
