


































import Vue from 'vue'

import BaseViewport from '@/components/layouts/BaseViewport.vue'
import BaseMain from '@/components/layouts/BaseMain.vue'
import BaseHeader from '@/components/layouts/BaseHeader.vue'
import HeaderTitle from '@/components/layouts/header/HeaderTitle.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import IconInfoCircle from '@/components/icons/IconInfoCircle.vue'
import LeftSidePanel from '@/components/login/panels/LeftSidePanel.vue'
import SidePanelSkeleton from '@/components/skeletons/SidePanelSkeleton.vue'
import LetterSkeleton from '@/components/skeletons/LetterSkeleton.vue'
import LoginBody from '@/components/login/LoginBody.vue'

export default Vue.extend({
  components: {
    BaseViewport,
    BaseMain,
    BaseHeader,
    HeaderTitle,
    ButtonIcon,
    IconInfoCircle,
    LeftSidePanel,
    SidePanelSkeleton,
    LetterSkeleton,
    LoginBody,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isLeftPanel: {
      type: Boolean,
      required: true,
    },
    isRightPanel: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
})
