import { AuthActionNames } from 'Auth/__types__/actions'
import { GetMedicalReportAction } from 'MedicalReport/__types__/actions'
import { MRActionNames } from 'MedicalReport/__types__/actions'
import { MRMutationNames } from 'MedicalReport/__types__/mutations'
import { NotifCenterActionNames } from 'NotifCenter/__types__/actions'
import { GetMedicalReport } from 'Services/__types__/medical-report'

export default function buildAction(
  getPMedicalReportFunc: GetMedicalReport,
): GetMedicalReportAction {
  return {
    async [MRActionNames.GET_MEDICAL_REPORT]({ rootState, dispatch, commit }) {
      commit(MRMutationNames.SET_MR_LOADING, true)

      const signOut = function () {
        dispatch(
          ('auth/' + AuthActionNames.SIGN_OUT) as AuthActionNames.SIGN_OUT,
          undefined,
          { root: true },
        )
      }

      if (!rootState.auth.isAuthenticated || rootState.auth.apiToken === null) {
        signOut()
      } else {
        const status = await getPMedicalReportFunc(rootState.auth.apiToken)

        // Display a notification related to status' props
        dispatch(
          ('notifCenter/' +
            NotifCenterActionNames.SET_NOTIF) as NotifCenterActionNames.SET_NOTIF,
          status,
          { root: true },
        )

        if (status.expiredToken) {
          signOut()
        } else if (status.medicalReport) {
          commit(MRMutationNames.SET_MEDICAL_REPORT, status.medicalReport)
        }
      }

      commit(MRMutationNames.SET_MR_LOADING, false)
    },
  }
}
