/*
 * Module: Notification Center
 */

import { NotifCenterMutationNames } from 'NotifCenter/__types__/mutations'
import { NotifCenterMutationTree } from 'NotifCenter/__types__/mutations'
import { Notification, NotifCenterState } from 'NotifCenter/__types__/state'

// Define mutations
export const notifCenterMutations: NotifCenterMutationTree = {
  [NotifCenterMutationNames.SET_NOTIF](
    state: NotifCenterState,
    payload: Notification,
  ) {
    state.notification = {
      type: payload.type,
      text: payload.text,
      createdAt: payload.createdAt,
    }
  },
  [NotifCenterMutationNames.RESET_NOTIF](state: NotifCenterState) {
    state.notification = undefined
  },
}
