export const UNALLOWED_CHAR_REGEXP_STR = '[^A-Z0-9]'

export function isAllowedCharInCode(str: string): boolean {
  if (new RegExp(UNALLOWED_CHAR_REGEXP_STR, 'gi').test(str)) {
    return false
  }
  return true
}

// Filter the token used to authenticate
export function filterToken(oldToken: string, newToken: string): string {
  const upperCase = newToken.toUpperCase()
  if (isAllowedCharInCode(upperCase)) {
    return upperCase
  } else {
    return oldToken
  }
}
