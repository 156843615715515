/*
 * Module: Medical Report
 */

import { MedicalReportState } from './state'
import { MedicalReport } from 'MedicalReport/actions/__types__/medical-report'
import { MutationTree } from 'vuex'

// Mutations enums
export enum MRMutationNames {
  SET_MR_LOADING = 'SET_MR_LOADING',
  SET_MEDICAL_REPORT = 'SET_MEDICAL_REPORT',
  RESET_MEDICAL_REPORT = 'RESET_MEDICAL_REPORT',
}

export interface MedicalReportMutationTree
  extends MutationTree<MedicalReportState>,
    MedicalReportMutations {}

// Mutation contracts
export type MedicalReportMutations<S = MedicalReportState> = {
  [MRMutationNames.SET_MR_LOADING](
    state: MedicalReportState,
    set: boolean,
  ): void
  [MRMutationNames.SET_MEDICAL_REPORT](
    state: S,
    medicalReport: MedicalReport,
  ): void
  [MRMutationNames.RESET_MEDICAL_REPORT](state: S): void
}
