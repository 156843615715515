

















































































import Vue from 'vue'

import Accordion from '@/components/panel/Accordion.vue'

export default Vue.extend({
  components: {
    Accordion,
  },
})
