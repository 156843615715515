



















import Vue from 'vue'

import IconLogo from '@/components/icons/IconLogo.vue'

export default Vue.extend({
  components: {
    IconLogo,
  },
})
