import {
  NotifCenterActionNames,
  ResetMessageAction,
} from 'NotifCenter/__types__/actions'
import { NotifCenterMutationNames } from 'NotifCenter/__types__/mutations'

export default function buildAction(): ResetMessageAction {
  return {
    [NotifCenterActionNames.RESET_NOTIF]({ commit }) {
      commit(NotifCenterMutationNames.RESET_NOTIF, undefined)
    },
  }
}
