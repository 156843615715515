/*
 * Module: Notification Center
 */

import { Module } from 'vuex'
import { buildNotifCenterState } from './state'
import { notifCenterMutations } from './mutations'
import { notifCenterActions } from './actions'
import { NotifCenterState } from 'NotifCenter/__types__/state'
import { RootState } from 'Store/__types__/store'

export const notifCenterModule: Module<NotifCenterState, RootState> = {
  namespaced: true,
  state: buildNotifCenterState(),
  mutations: notifCenterMutations,
  actions: notifCenterActions,
}
