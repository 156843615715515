







































import Vue from 'vue'

export default Vue.extend({
  computed: {
    hasLeftSlot(): boolean {
      return !!this.$slots.left
    },
    hasSubTitleSlot(): boolean {
      return !!this.$slots.subTitle
    },
    hasRightSlot(): boolean {
      return !!this.$slots.right
    },
  },
})
