import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
import { useStore } from 'Store/store'
import { UIMutationNames } from 'UI/__types__/mutations'

const Store = useStore()

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'root',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // Show left side panel on bigger screens than v-tablets
  if (window.innerWidth >= 1024 && !Store.state.ui.isLeftPanel) {
    Store.commit(
      ('ui/' +
        UIMutationNames.SET_LEFT_PANEL) as UIMutationNames.SET_LEFT_PANEL,
      true,
      { root: true },
    )
  }
  // Show right side panel on bigger screens than v-tablets and small laptops
  if (window.innerWidth >= 1280 && !Store.state.ui.isRightPanel) {
    Store.commit(
      ('ui/' +
        UIMutationNames.SET_RIGHT_PANEL) as UIMutationNames.SET_RIGHT_PANEL,
      true,
      { root: true },
    )
  }

  // Redirect to login page if isAuthenticated is false
  if (to.name !== 'root' && !Store.state.auth.isAuthenticated) {
    next({ name: 'root' })
  } else if (to.name === 'root' && Store.state.auth.isAuthenticated) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

export default router
