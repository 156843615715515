/*
 * Module: UI
 */

import buildToggleLeftPanelAction from './actions/toggle-left-panel'
import buildToggleRightPanelAction from './actions/toggle-right-panel'
import buildSetModalContentAction from './actions/set-modal-content'
import buildToggleModalAction from './actions/toggle-modal'
import { UIActionTree } from 'UI/__types__/actions'

export const uiActions: UIActionTree = {
  ...buildToggleLeftPanelAction(),
  ...buildToggleRightPanelAction(),
  ...buildToggleModalAction(),
  ...buildSetModalContentAction(),
}
