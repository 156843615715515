import { UIActionNames, SetModalContentAction } from 'UI/__types__/actions'
import { UIMutationNames } from 'UI/__types__/mutations'

export default function buildAction(): SetModalContentAction {
  return {
    [UIActionNames.SET_MODAL_CONTENT]({ commit }, payload) {
      commit(UIMutationNames.SET_MODAL_CONTENT, payload)
    },
  }
}
