/*
 * Module: Medical Report
 */

import { Module } from 'vuex'
import { buildMedicalReportState } from './state'
import { medicalReportMutations } from './mutations'
import { medicalReportGetters } from './getters'
import { medicalReportActions } from './actions'
import { MedicalReportState } from 'MedicalReport/__types__/state'
import { RootState } from 'Store/__types__/store'

export const medicalReportModule: Module<MedicalReportState, RootState> = {
  namespaced: true,
  state: buildMedicalReportState(),
  mutations: medicalReportMutations,
  getters: medicalReportGetters,
  actions: medicalReportActions,
}
