





























import Vue from 'vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import IconChevronRight from '@/components/icons/IconChevronRight.vue'

export default Vue.extend({
  components: {
    IconChevronDown,
    IconChevronRight,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isActive: false,
    }
  },

  methods: {
    handleClick(): void {
      this.isActive = !this.isActive
    },
  },
})
