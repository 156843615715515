










import { mapState, mapActions } from 'vuex'
import { AuthActionNames } from 'Auth/__types__/actions'
import { UIActionNames } from 'UI/__types__/actions'
import Login from '@/components/login/Login.vue'

export default {
  components: {
    Login,
  },

  computed: {
    ...mapState('ui', ['isLeftPanel', 'isRightPanel']),
    ...mapState('auth', ['isLoading']),
  },

  methods: {
    ...mapActions('auth', {
      signIn: AuthActionNames.SIGN_IN,
    }),

    ...mapActions('ui', {
      toggleLeftPanel: UIActionNames.TOGGLE_LEFT_PANEL,
    }),

    login(token: string): void {
      this.signIn(token)
    },
  },
}
