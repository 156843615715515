/*
 * Module: Auth
 */

import buildSignInAction from 'Auth/actions/sign-in'
import buildSignOutAction from 'Auth/actions/sign-out'
import { buildSignIn } from 'Services/authentication'
import Axios from 'Services/axios.config'
import { AuthActionTree } from 'Auth/__types__/actions'

export const authActions: AuthActionTree = {
  ...buildSignInAction(buildSignIn(Axios.post)),
  ...buildSignOutAction(),
}
