import Vue from 'vue'
import Vuex from 'vuex'
import { uiModule } from 'UI/module'
import { authModule } from 'Auth/module'
import { medicalReportModule } from 'MedicalReport/module'
import { AugmentedStore } from 'Store/__types__/store'
import { notifCenterModule } from 'NotifCenter/module'

Vue.use(Vuex)

export const store: AugmentedStore = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    ui: uiModule,
    auth: authModule,
    medicalReport: medicalReportModule,
    notifCenter: notifCenterModule,
  },
})

// To use store using Composition API...
// ... we must access it via useStore hook, which just returns our store
export function useStore(): AugmentedStore {
  return store
}
