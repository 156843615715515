/*
 * Module: Notification Center
 */

import buildSetNotifAction from './actions/set-notif'
import buildResetNotifAction from './actions/reset-notif'
import { NotifCenterActionTree } from 'NotifCenter/__types__/actions'

export const notifCenterActions: NotifCenterActionTree = {
  ...buildSetNotifAction(),
  ...buildResetNotifAction(),
}
