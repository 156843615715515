













import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import Alert from '@/components/alert.vue'
import { NotifCenterActionNames } from 'NotifCenter/__types__/actions'

export default Vue.extend({
  name: 'App',
  components: { Alert },
  computed: {
    ...mapState('auth', ['isAuthenticated']),
    ...mapState('notifCenter', ['notification']),
  },
  methods: {
    ...mapActions('notifCenter', {
      resetMessage: NotifCenterActionNames.RESET_NOTIF,
    }),
  },
  watch: {
    isAuthenticated(newValue: boolean): void {
      this.$router.push(newValue ? 'dashboard' : 'root')
    },
  },
})
