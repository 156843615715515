






















import Vue from 'vue'

import BaseSidePanel from '@/components/layouts/BaseSidePanel.vue'
import HeaderTitle from '@/components/layouts/header/HeaderTitle.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import IconInfoCircle from '@/components/icons/IconInfoCircle.vue'
import AboutBody from '@/components/login/panels/AboutBody.vue'
import FooterLogo from '@/components/panel/FooterLogo.vue'

export default Vue.extend({
  components: {
    BaseSidePanel,
    HeaderTitle,
    ButtonIcon,
    IconInfoCircle,
    AboutBody,
    FooterLogo,
  },

  props: {},

  data() {
    return {}
  },

  computed: {},

  methods: {},
})
