/*
 * Module: UI
 */

import { Module } from 'vuex'
import { buildUIState } from './state'
import { uiMutations } from './mutations'
import { uiActions } from './actions'
import { UIState } from 'UI/__types__/state'
import { RootState } from 'Store/__types__/store'

export const uiModule: Module<UIState, RootState> = {
  namespaced: true,
  state: buildUIState(),
  mutations: uiMutations,
  actions: uiActions,
}
