/*
 * Module: Auth
 */

import { Module } from 'vuex'
import { buildAuthState } from './state'
import { authMutations } from './mutations'
import { authActions } from './actions'
import { AuthState } from 'Auth/__types__/state'
import { RootState } from 'Store/__types__/store'

export const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: buildAuthState(),
  mutations: authMutations,
  actions: authActions,
}
