/*
 * Module: Medical Record
 */

import buildMedicalReportAction from 'MedicalReport/actions/get-medical-report'
import { buildGetMedicalReport } from 'Services/medical-report'
import Axios from 'Services/axios.config'
import { MedicalReportActionTree } from 'MedicalReport/__types__/actions'

export const medicalReportActions: MedicalReportActionTree = {
  ...buildMedicalReportAction(buildGetMedicalReport(Axios.get)),
}
