'use strict'

import axios from 'axios'
import APP_VERSION from '@/utils/version'

// NOTE: Full config: https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.VUE_APP_API_URL || ''
// axios.defaults.headers.common['x-biloba-desk'] = APP_VERSION
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_API_URL || '',
  // FIXME: We should use a specific header for RD
  // ask @did or @jdoucy to provide it from the API
  headers: { 'X-biloba-desk': APP_VERSION },
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const Axios = axios.create(config)

// Add a request interceptor
Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
Axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      return error.response
    }
    // Do something with response error
    return Promise.reject(error)
  },
)

export default Axios
