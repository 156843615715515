/*
 * Module: Medical Report
 */

import { MRMutationNames } from 'MedicalReport/__types__/mutations'
import { MedicalReportMutationTree } from 'MedicalReport/__types__/mutations'
import { MedicalReportState } from 'MedicalReport/__types__/state'
import { MedicalReport } from 'MedicalReport/actions/__types__/medical-report'

// Define mutations
export const medicalReportMutations: MedicalReportMutationTree = {
  [MRMutationNames.SET_MR_LOADING](state: MedicalReportState, set: boolean) {
    state.isLoading = set
  },
  [MRMutationNames.SET_MEDICAL_REPORT](
    state: MedicalReportState,
    medicalReport: MedicalReport,
  ) {
    state.medicalReport = medicalReport
  },
  [MRMutationNames.RESET_MEDICAL_REPORT](state: MedicalReportState) {
    state.medicalReport = null
  },
}
