






import Vue, { PropType } from 'vue'

const buttonClasses: Record<string, string> = {
  primary:
    'px-5 py-2 rounded focus:outline-none focus:shadow-outline bg-primary text-white disabled:opacity-50',
}

export default Vue.extend({
  props: {
    type: {
      type: String as PropType<'primary'>,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    buttonClasses(): string {
      return buttonClasses[this.type]
    },
  },

  methods: {},
})
