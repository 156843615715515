/*
 * Module: Notification Center
 */

import { ActionTree } from 'vuex'
import { RootState } from 'Store/__types__/store'
import { NotifCenterCommit } from './module'
import { NotifCenterState } from './state'
import { AuthenticationStatus } from 'Services/__types__/authentication'
import { MedicalReportStatus } from 'Services/__types__/medical-report'

// Action enums
export enum NotifCenterActionNames {
  SET_NOTIF = 'SET_NOTIF',
  RESET_NOTIF = 'RESET_NOTIF',
}

export interface NotifCenterActionTree
  extends ActionTree<NotifCenterState, RootState>,
    NotifCenterActions {}

export interface NotifCenterActions
  extends SetMessageAction,
    ResetMessageAction {}

export interface SetMessageAction {
  [NotifCenterActionNames.SET_NOTIF](
    { commit }: NotifCenterCommit,
    status: AuthenticationStatus | MedicalReportStatus,
  ): void
}
export interface ResetMessageAction {
  [NotifCenterActionNames.RESET_NOTIF]({ commit }: NotifCenterCommit): void
}
