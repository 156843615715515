/*
 * Module: Auth
 */

import { AuthState } from 'Auth/__types__/state'

// Create initial state
export const buildAuthState = function (): AuthState {
  return {
    isLoading: false,
    isAuthenticated: false,
    apiToken: null,
  }
}
