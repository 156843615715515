















































import Vue, { PropType } from 'vue'

import BaseHeader from '@/components/layouts/BaseHeader.vue'

export default Vue.extend({
  components: {
    BaseHeader,
  },

  props: {
    panelPosition: {
      type: String as PropType<'left' | 'right'>,
      required: true,
    },
  },

  computed: {
    hasFooterSlot(): boolean {
      return !!this.$slots.footer
    },
  },
})
