import { render, staticRenderFns } from "./BaseMain.vue?vue&type=template&id=7eb51901&scoped=true&"
var script = {}
import style0 from "./BaseMain.vue?vue&type=style&index=0&id=7eb51901&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eb51901",
  null
  
)

export default component.exports