/*
 * Module: Auth
 */

import { AuthState } from './state'
import { MutationTree } from 'vuex'

// Mutations enums
export enum AuthMutationNames {
  SET_AUTH_LOADING = 'SET_AUTH_LOADING',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}

export interface AuthMutationTree
  extends MutationTree<AuthState>,
    AuthMutations {}

// Mutation contracts
export type AuthMutations<S = AuthState> = {
  [AuthMutationNames.SET_AUTH_LOADING](state: S, set: boolean): void
  [AuthMutationNames.SIGN_IN](state: S, apiToken: string): void
  [AuthMutationNames.SIGN_OUT](state: S): void
}
