import { Get } from 'Services/__types__/api'

// Types
import {
  GetMedicalReport,
  MedicalReportStatus,
  MedicalReportAPIResponse,
} from 'Services/__types__/medical-report'

export const buildGetMedicalReport = function (
  getFunc: Get<MedicalReportAPIResponse>,
): GetMedicalReport {
  return async function (apiToken: string) {
    const status: MedicalReportStatus = {
      request: 'getMedicalReport',
      medicalReport: null,
      createdAt: Date.now(),
    }

    try {
      const response = await getFunc('/doctors/view-medical-report', {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      if (response.status === 200) {
        status.medicalReport = response.data.medicalReport
      } else {
        status.expiredToken = true
      }
    } catch (err) {
      status.errorMessage = err.message
    }

    return status
  }
}
