import { UIActionNames, ToggleModalAction } from 'UI/__types__/actions'
import { UIMutationNames } from 'UI/__types__/mutations'

export default function buildAction(): ToggleModalAction {
  return {
    [UIActionNames.TOGGLE_MODAL]({ state, commit }) {
      commit(UIMutationNames.TOGGLE_MODAL, !state.modal.isVisible)
    },
  }
}
