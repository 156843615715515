/*
 * Module: UI
 */

import { ActionTree } from 'vuex'
import { RootState } from 'Store/__types__/store'
import { UIActionContext, UICommit } from './module'
import { ModalContent, UIState } from './state'

// Action enums
export enum UIActionNames {
  TOGGLE_LEFT_PANEL = 'TOGGLE_LEFT_PANEL',
  TOGGLE_RIGHT_PANEL = 'TOGGLE_RIGHT_PANEL',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  SET_MODAL_CONTENT = 'SET_MODAL_CONTENT',
}

export interface UIActionTree
  extends ActionTree<UIState, RootState>,
    UIActions {}

export interface UIActions
  extends ToggleLeftPanelAction,
    ToggleRightPanelAction,
    ToggleModalAction,
    SetModalContentAction {}

export interface ToggleLeftPanelAction {
  [UIActionNames.TOGGLE_LEFT_PANEL](actionContext: UIActionContext): void
}

export interface ToggleRightPanelAction {
  [UIActionNames.TOGGLE_RIGHT_PANEL](actionContext: UIActionContext): void
}

export interface ToggleModalAction {
  [UIActionNames.TOGGLE_MODAL](actionContext: UIActionContext): void
}

export interface SetModalContentAction {
  [UIActionNames.SET_MODAL_CONTENT](
    commit: UICommit,
    payload: ModalContent,
  ): void
}
