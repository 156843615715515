















import Vue, { PropType } from 'vue'
import { notificationTypes } from 'NotifCenter/__types__/state'

const alertClasses: Record<string, string> = {
  info: 'bg-blue-200 text-blue-700',
  success: 'bg-green-200 text-green-700',
  warning: 'bg-yellow-200 text-yellow-700',
  danger: 'bg-red-200 text-red-700',
}

export default Vue.extend({
  props: {
    type: {
      type: String as PropType<notificationTypes>,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    createdAt: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      timeOut: 0,
    }
  },

  computed: {
    alertClasses(): string {
      return alertClasses[this.type]
    },
  },

  methods: {
    forceDismiss(): void {
      this.$emit('dismiss')
      this.$destroy()
    },

    async autoDismiss(): Promise<void> {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.$emit('dismiss')
      }, 5000)
    },
  },

  beforeUpdate(): void {
    this.autoDismiss()
  },

  mounted(): void {
    this.autoDismiss()
  },

  beforeDestroy(): void {
    clearTimeout(this.timeOut)
  },
})
