/*
 * Module: Medical Report
 */

import { MedicalReportState } from 'MedicalReport/__types__/state'

// Create initial state
export const buildMedicalReportState = function (): MedicalReportState {
  return {
    isLoading: false,
    medicalReport: null,
  }
}
