












































import Vue from 'vue'

import BaseSidePanel from '@/components/layouts/BaseSidePanel.vue'
import { PropType } from 'vue/types/options'

export default Vue.extend({
  components: {
    BaseSidePanel,
  },

  props: {
    panelPosition: {
      type: String as PropType<'left' | 'right'>,
      required: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
})
