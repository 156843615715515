/*
 * Module: Notification Center
 */

import { NotifCenterState } from 'NotifCenter/__types__/state'

// Create initial state
export const buildNotifCenterState = function (): NotifCenterState {
  return {
    notification: undefined,
  }
}
