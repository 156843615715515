/*
 * Module: Auth
 */

import { AuthCommit } from './module'
import { ActionTree } from 'vuex'
import { RootState } from 'Store/__types__/store'
import { AuthState } from './state'
import { NotifCenterDispatch } from 'NotifCenter/__types__/module'

// Action enums
export enum AuthActionNames {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}

export interface AuthActionTree
  extends ActionTree<AuthState, RootState>,
    AuthActions {}

export interface AuthActions extends SignInAction, SignOutAction {}

export interface SignInAction {
  [AuthActionNames.SIGN_IN](
    { commit, dispatch }: AuthCommit & NotifCenterDispatch,
    payload: string,
  ): void
}

export interface SignOutAction {
  [AuthActionNames.SIGN_OUT]({ commit }: AuthCommit): void
}
