/*
 * Module: Notification Center
 */

import { Notification, NotifCenterState } from './state'
import { MutationTree } from 'vuex'

// Mutations enums
export enum NotifCenterMutationNames {
  SET_NOTIF = 'SET_NOTIF',
  RESET_NOTIF = 'RESET_NOTIF',
}

export interface NotifCenterMutationTree
  extends MutationTree<NotifCenterState>,
    NotifCenterMutations {}

// Mutation contracts
export type NotifCenterMutations<S = NotifCenterState> = {
  [NotifCenterMutationNames.SET_NOTIF](state: S, payload: Notification): void
  [NotifCenterMutationNames.RESET_NOTIF](state: S): void
}
