/*
 * Module: Auth
 */

import { AuthMutationNames } from 'Auth/__types__/mutations'
import { AuthMutationTree } from 'Auth/__types__/mutations'
import { AuthState } from 'Auth/__types__/state'

// Define mutations
export const authMutations: AuthMutationTree = {
  [AuthMutationNames.SET_AUTH_LOADING](state: AuthState, set: boolean) {
    state.isLoading = set
  },
  [AuthMutationNames.SIGN_IN](state: AuthState, apiToken: string) {
    state.isAuthenticated = true
    state.apiToken = apiToken
  },
  [AuthMutationNames.SIGN_OUT](state: AuthState) {
    state.isAuthenticated = false
    state.apiToken = null
  },
}
