import Vue from 'vue'
import { store } from 'Store/store'
import App from '@/App.vue'
import router from '@/router'
import i18n from '@/plugins/i18n'
import '@/plugins/vTooltip'
import '@/assets/tailwind.css'
import '@/assets/vTooltip.css'

Vue.config.productionTip = false

// Init theme mode
// NOTE: if your OS is in dark mode but you want to force light-mode,
// you can add theme = light in your localStorage
if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.documentElement.classList.add('dark')
} else {
  document.documentElement.classList.remove('dark')
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
