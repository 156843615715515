/*
 * Module: Medical Report
 */

import { ActionTree } from 'vuex'
import { RootState } from 'Store/__types__/store'
import { MedicalReportState } from './state'
import { AuthActionContext } from 'Auth/__types__/module'
import { NotifCenterActionContext } from 'NotifCenter/__types__/module'
import { MRActionContext } from './module'

// Action enums
export enum MRActionNames {
  GET_MEDICAL_REPORT = 'GET_MEDICAL_REPORT',
}

export interface MedicalReportActionTree
  extends ActionTree<MedicalReportState, RootState>,
    MedicalReportActions {}

export type MedicalReportActions = GetMedicalReportAction

export interface GetMedicalReportAction {
  [MRActionNames.GET_MEDICAL_REPORT]({
    state,
    rootState,
    dispatch,
    commit,
  }: {
    state: MedicalReportState
    rootState: RootState
    dispatch: AuthActionContext['dispatch'] &
      NotifCenterActionContext['dispatch']
    commit: MRActionContext['commit']
  }): void
}
