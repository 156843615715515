import i18n from '@/plugins/i18n'
import {
  NotifCenterActionNames,
  SetMessageAction,
} from 'NotifCenter/__types__/actions'
import { NotifCenterMutationNames } from 'NotifCenter/__types__/mutations'

export default function buildAction(): SetMessageAction {
  return {
    [NotifCenterActionNames.SET_NOTIF]({ commit }, requestStatus) {
      //
      // Authentication
      //

      // Request: Signin
      if (requestStatus.request === 'signin') {
        if (requestStatus.authenticated && requestStatus.apiToken) {
          commit(NotifCenterMutationNames.SET_NOTIF, {
            type: 'success',
            text: i18n.t('alerts.auth.signedIn'),
            createdAt: requestStatus.createdAt,
          })
        } else {
          commit(NotifCenterMutationNames.SET_NOTIF, {
            type: 'warning',
            text: i18n.t('alerts.auth.wrongToken'),
            createdAt: requestStatus.createdAt,
          })
        }
      }

      //
      // Medical Report
      // NOTE: FYI: Success has no notification!
      //

      // Request : GetMedicalReport
      if (requestStatus.request === 'getMedicalReport') {
        if (requestStatus.expiredToken) {
          commit(NotifCenterMutationNames.SET_NOTIF, {
            type: 'danger',
            text: i18n.t('alerts.auth.wrongToken'),
            createdAt: requestStatus.createdAt,
          })
        }
      }

      //
      // For all types of requests
      // Display the "catch" error
      //
      if (requestStatus.errorMessage) {
        commit(NotifCenterMutationNames.SET_NOTIF, {
          type: 'danger',
          text: `${requestStatus.errorMessage} | (Request type: ${requestStatus.request})`,
          createdAt: requestStatus.createdAt,
        })
      }
    },
  }
}
