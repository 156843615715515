/*
 * Module: UI
 */

import { UIMutationNames } from 'UI/__types__/mutations'
import { UIMutationTree } from 'UI/__types__/mutations'
import { ModalContent, UIState } from 'UI/__types__/state'

// Define mutations
export const uiMutations: UIMutationTree = {
  [UIMutationNames.SET_LOADING](state: UIState, set: boolean) {
    state.isLoading = set
  },
  [UIMutationNames.SET_LEFT_PANEL](state: UIState, set: boolean) {
    state.isLeftPanel = set
  },
  [UIMutationNames.SET_RIGHT_PANEL](state: UIState, set: boolean) {
    state.isRightPanel = set
  },
  [UIMutationNames.TOGGLE_MODAL](state: UIState, set: boolean) {
    // Protection: Disallow modal visibility mutation to true...
    //... if there is no content in the modal
    if (state.modal.content) {
      state.modal.isVisible = set
    } else {
      // Cannot show an empty modal without content
      state.modal.isVisible = false
    }
  },
  [UIMutationNames.SET_MODAL_CONTENT](state: UIState, set: ModalContent) {
    state.modal.content = set
  },
}
