/*
 * Module: Medical Report
 */

import getPatientProfileGetter from 'MedicalReport/getters/get-patient-profile'
import getReportDetailsGetter from 'MedicalReport/getters/get-report-details'
import { MedicalReportGetterTree } from 'MedicalReport/__types__/getters'

export const medicalReportGetters: MedicalReportGetterTree = {
  ...getPatientProfileGetter,
  ...getReportDetailsGetter,
}
