import { Post } from 'Services/__types__/api'
import {
  AuthenticationAPIResponse,
  AuthenticationStatus,
  SignIn,
} from 'Services/__types__/authentication'

export const buildSignIn = function (
  postFunc: Post<AuthenticationAPIResponse>,
): SignIn {
  return async function (token: string) {
    const safeToken = token.trim().replace(' ', '')

    const status = {
      request: 'signin',
      authenticated: false,
      createdAt: Date.now(),
    } as AuthenticationStatus

    if (safeToken.length === 6) {
      try {
        const response = await postFunc('/doctors/authenticate', {
          temporaryCode: safeToken,
        })

        if (response.status === 200) {
          status.authenticated = true
          status.apiToken = response.data.apiToken
        }
      } catch (err) {
        status.errorMessage = err.message
      }
    }

    return status
  }
}
